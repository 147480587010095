import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { delay, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LOCAL_STORAGE_SAFARI_UPDATE_KEY } from '../common-modules/common-pwa/services/common-pwa-update.service';
import moment from 'moment';
import { LocalizationService } from './services/localization.service';
import { INITIAL_LANGUAGE, LANGUAGE_LOCAL_STORAGE_KEY } from '../config';
import { environment } from '../../environments/environment';
import { SingleTenantService } from '../services/single-tenant.service';

export function appInitializerFactory(translate: TranslateService, localizationService: LocalizationService, injector: Injector, singleTenantService: SingleTenantService) {
  return () => new Promise<any>(async (resolve: any) => {
    await singleTenantService.initSingleTenantApp();
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const defaultLanguage = INITIAL_LANGUAGE;
      let langToSet = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
      if (!langToSet) {
        langToSet = defaultLanguage;
      }
      localizationService.registerLocale(langToSet);
      localizationService.getAvailableLanguagesWithoutTesting().toPromise().then(res => {
        translate.addLangs(res.map(item => item.code));
      });
      // SAFARI PWA UPDATE FIX
      // TODO find different solution, after safari browser pwa updated first request failed
      const wasUpdated = localStorage.getItem(LOCAL_STORAGE_SAFARI_UPDATE_KEY);
      of({}).pipe(
        // tap(() => console.log('wwwwww')),
        delay(wasUpdated && wasUpdated === 'true' ? 100 : 0),
        // tap(() => console.log('wwwwww')),
        mergeMap(() => {
          if (environment.production) {
            translate.setDefaultLang(defaultLanguage);
          }
          return translate.use(langToSet);
        })
      ).subscribe(() => {
        console.info(`[INITIALIZER] Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`[INITIALIZER] Problem with '${langToSet}' language initialization.'`);
        resolve(null);
      }, () => {
        console.log('[INITIALIZER] App language loaded.');
        const uA = navigator.userAgent;
        const vendor = navigator.vendor;
        if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
          localStorage.setItem(LOCAL_STORAGE_SAFARI_UPDATE_KEY, 'false');
        }
        resolve(null);
      });
    });
  });
}
