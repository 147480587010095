import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '@app/environments/environment';
import { InterceptorSkipHeader } from '../auth/interceptors/auth.interceptor';

export interface SingleTenantConfig {
  is_regular_login_disabled: boolean;
  is_google_login_disabled: boolean;
  is_azure_login_disabled: boolean;
  is_okta_login_disabled: boolean;
  ats_portal_url: string;
  backed_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class SingleTenantService {
  private singleTenantConfig: SingleTenantConfig;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public getSingleTenantConfigRequest(): Observable<SingleTenantConfig> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http.get<SingleTenantConfig>(environment.singleTenantInitConfigUrl(), { headers });
  }

  public getSingleTenantConfig(): SingleTenantConfig {
    return environment.isSingleTenantApp ? this.singleTenantConfig : null;
  }

  public async initSingleTenantApp(): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve): Promise<void> => {
      if (environment.isSingleTenantApp) {
        try {
          const singleTenantConfig = await firstValueFrom(this.getSingleTenantConfigRequest());
          environment.backendUrl = singleTenantConfig.backed_url;
          environment.atsPortalUrl = singleTenantConfig.ats_portal_url;
          this.singleTenantConfig = singleTenantConfig;
          resolve();
        } catch (err) {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  public isNotSingleTenantAppGuard(redirect?: boolean): boolean {
    if (environment.isSingleTenantApp) {
      if (redirect) {
        this.router.navigate(['/', 'app', 'dashboard']);
      }
      return false;
    } else {
      return true;
    }
  }

  // TODO: change guards with options from User/Company after BE changes
  public isGoogleIntegrationForbiddenGuard(routing?: boolean): boolean {
    return !this.isNotSingleTenantAppGuard(routing);
  }

  public isMicrosoftIntegrationForbiddenGuard(routing?: boolean): boolean {
    return !this.isNotSingleTenantAppGuard(routing);
  }

  public singleTenantCanRegularLoginGuard(): boolean {
    if (this.getSingleTenantConfig()?.is_regular_login_disabled) {
      this.router.navigate(['/', 'app']);
      return false;
    } else {
      return true;
    }
  }
}
