import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { TESTING_LANGUAGE_CODE } from '../../config';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import * as pako from 'pako';

export const token = new InjectionToken('string');

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  constructor(private http: HttpClient,
              // private translateModuleText
              // private toast: NbToastrService
  ) {
  }


  getTranslation(lang: string): Observable<any> {
    // ./assets/i18n/
    // ${lang}/${lang}_${this.translateModuleText}
    // console.log('LOAD TRANSLATES');
    if (lang === TESTING_LANGUAGE_CODE) {
      return of({});
    }
    return this.http.get(`${environment.translatesS3Link}/translate_fe/${lang}`, {responseType: 'arraybuffer'}).pipe(
      map((response) => {
        const inflatedData = pako.inflate(response, {to: 'string'});
        const parsedJson = JSON.parse(inflatedData);
        // console.log(parsedJson);
        return parsedJson;
      }),
      catchError(error => {
        return this.http.get(`${environment.translatesUrl}?locale=${lang}&category=frontend`);
      })
    );
  }
}
