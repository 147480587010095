import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';

export const changeLanguageActions = createActionGroup({
  source: 'Auth - Change Language',
  events: {
    start: props<{ localeCode: string }>(),
    success: props<{ response: V1AuthUser; localeCode: string }>(),
    error: emptyProps(),
  },
});
