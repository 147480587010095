import { DEFAULT_ROUTER_FEATURENAME, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { navigationReducer, NavigationState } from './reducers/navigation.reducer';

export type AppState = {
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
  navigation: NavigationState;
};

export const appReducers: ActionReducerMap<AppState> = {
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  navigation: navigationReducer,
};
