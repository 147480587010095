import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slnk-common-loading',
  templateUrl: './common-loading.component.html',
  styleUrls: ['./common-loading.component.scss']
})
export class CommonLoadingComponent implements OnInit {
  @Input() isPrimary: boolean;
  @HostBinding('class.absoluteLoading') @Input() absolute = false;
  constructor() {
  }

  ngOnInit(): void {
  }

}
