import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first, mergeMap, switchMap, tap } from 'rxjs/operators';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { environment } from '@app/environments/environment';
import Nullable from '@app/models/typescript/nullable.model';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { logoutActions } from '../store/actions/logout.actions';
import { refreshTokenActions } from '../store/actions/refresh-token.actions';
import { selectAccessToken, selectRefreshToken } from '../store/selectors/token.selectors';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // Serves as storage of refresh token request
  public tokenRefreshed$!: Nullable<Observable<unknown>>;

  constructor(
    private store: Store,
    private v1_AuthService: V1_AuthService,
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    } else if (!req.url.includes(environment.translatesS3Link) && !req.url.includes(environment.translatesUrl)) {
      return this.store.select(selectAccessToken).pipe(
        first(),
        mergeMap(token => this.handleRequest(req, next, token)),
      );
    } else {
      return next.handle(req);
    }
  }

  /**
   * Adds backdoor token header to requests on dev. environments.
   * Handles 401 errors and covers refresh token logic.
   */
  private handleRequest(req: HttpRequest<unknown>, next: HttpHandler, accessToken: Nullable<string>): Observable<HttpEvent<unknown>> {
    if (accessToken) {
      req = this.authorizeRequest(req, accessToken);
    }

    return next.handle(req).pipe(
      catchError(err => {
        if (this.isForcePasswordUpdateNeeded(err)) {
          this.store.dispatch(logoutActions.logoutSetPassword({ token: err.error.data.token }));
          return throwError(() => err);
        }

        if (err.status === 503 && req.url.includes(environment.backendUrl)) {
          this.store.dispatch(navigationActions.navigateTo({ commands: ['/', 'under-construction'] }));
          return throwError(() => err);
        }

        if (err.status >= 400 && err.status !== 401) {
          return throwError(() => err);
        }

        if (this.isRefreshTokenRequestFailed(req, err)) {
          this.store.dispatch(logoutActions.logoutWithStandardRedirect());
          return throwError(() => err);
        }

        if (err.status === 401 && err.error?.hash_expired) {
          this.store.dispatch(logoutActions.logoutWithStandardRedirect());
          return throwError(() => err);
        }

        if (err.status === 401 && err.error?.token_expired) {
          return this.handle401Error(req, next);
        }

        /**
         * To handle 401 errors from external sources like Google, Microsoft, etc.
         */
        if (err.status === 401 && req.url.includes('/auth/')) {
          return throwError(() => err);
        }

        /**
         * Fallback 401 error handling, all existing 401 errors should be handled above.
         * This is needed for file download 401 errors and others where the back-end
         * framework does not return a proper error response, but is still a 401.
         */
        if (err instanceof HttpErrorResponse && err.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(() => err);
      }),
    );
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.tokenRefreshed$) {
      this.tokenRefreshed$ = this.store.select(selectRefreshToken).pipe(
        first(),
        switchMap(refreshToken =>
          this.v1_AuthService.refreshToken({ refresh_token: refreshToken ?? '' }).pipe(
            tap({
              next: response => this.store.dispatch(refreshTokenActions.success({ response: response.data })),
              error: () => this.store.dispatch(refreshTokenActions.error()),
            }),
            finalize(() => {
              // Once token is refreshed, remove the reference so we can refresh once again
              this.tokenRefreshed$ = null;
            }),
          ),
        ),
      );
    }

    // Once token is successfully refreshed, pass the original request
    return this.tokenRefreshed$.pipe(mergeMap(() => this.intercept(req, next)));
  }

  private authorizeRequest(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }

  private isForcePasswordUpdateNeeded(err: HttpErrorResponse): boolean {
    return err.error?.force_password_update && err.error.status_code === 403 && err.error.data.token;
  }

  private isRefreshTokenRequestFailed(request: HttpRequest<unknown>, err: HttpErrorResponse): boolean {
    return request.url.indexOf('/auth/refresh') > 0 && err.status === 401;
  }
}
