import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface CommonCardEvent {
  event: COMMON_CARD_EVENT_TYPE;
}

export enum COMMON_CARD_EVENT_TYPE {
  CLOSE = 'close'
}

@Component({
  selector: 'slnk-common-card-header',
  templateUrl: './common-card-header.component.html',
  styleUrls: ['./common-card-header.component.scss']
})
export class CommonCardHeaderComponent implements OnInit {

  @Input() showBackButton: boolean;
  @Input() defaultBackButtonRoute: string;
  @Input() showCloseButton: boolean;
  @Output() cardEvent = new EventEmitter<CommonCardEvent>();

  constructor() {
  }

  ngOnInit() {
  }

  close() {
    this.cardEvent.emit({event: COMMON_CARD_EVENT_TYPE.CLOSE});
  }

}
