import { createActionGroup, props } from '@ngrx/store';

import {
  POST_V1_AuthLoginParams,
  V1AuthLogin,
  V1AuthMFALoginRequired,
  V1AuthMFASetupAvailable,
  V1AuthMFASetupRequired,
} from '@app/models/api/v1/auth/auth.model';
import APIValidationError from '@app/models/api/validation-error.model';

export const loginActions = createActionGroup({
  source: 'Auth - Login',
  events: {
    start: props<{ params: POST_V1_AuthLoginParams }>(),
    setupMFA: props<{ response: V1AuthMFASetupAvailable | V1AuthMFASetupRequired }>(),
    useMFA: props<{ response: V1AuthMFALoginRequired }>(),
    success: props<{ response: V1AuthLogin; skipNavigation?: boolean; asAdmin?: boolean; message?: string }>(),
    error: props<{ validationErrors?: APIValidationError; errorMessage?: string }>(),
    changedLanguageTo: props<{ language: string }>(),
  },
});
