import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { initialPersistanceActions } from '../actions/persistance.actions';
import { userInfoActions } from '../actions/user-info.actions';

@Injectable()
export class UserInfoEffects {
  public readonly getUserOnSuccessTokenLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialPersistanceActions.tokensLoaded),
      map(() => userInfoActions.start()),
    ),
  );

  public readonly getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfoActions.start, userInfoActions.refetch),
      exhaustMap(() =>
        this.v1_AuthService.getUserInfo().pipe(
          map(response => userInfoActions.success({ response: response.data })),
          catchError(() => of(userInfoActions.error())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
