import packageInfo from '../../package.json';

export const environment = {
  production: false,
  removeAppLogs: false,
  appVersionNumber: packageInfo.version,
  appVersion: 'v' + packageInfo.version + '-dev',
  backendUrl: 'https://api-dev.sloneek.com/',
  translatesS3Link: 'https://sloneek-dev.s3.eu-west-1.amazonaws.com/translate',
  translatesUrl: 'https://api-dev.sloneek.com/v1/translations',
  atsPortalUrl: 'https://jobs-dev.sloneek.com/',
  atsPortalUrlV2: 'https://jobs-dev.sloneek.com/ats-v2/',
  defaulSloneekPhotoUrl: '/assets/pwa/logo/sloneek_logo_avatar.svg',
  knowledgebaseUrl: 'https://help.sloneek.com',
  stripeApiKey: 'pk_test_51L5RSUEq08xkehoVqFJQkVPFozIAqYJCgQ82HiR2hK2zWThpNnR9iP8DjgvnQfMx2FxECAKmvIUky3btlAa284xB001kQxrToJ',
  googleMapsApiKey: 'AIzaSyAeL9qNC5I0IxYFyzk4rJ_SYy7VfFLXXiE',
  googleTranslateApiKey: 'AIzaSyBTsx6Wj3vW2OA2vyhvYtTUF6p3u8ecrIA',
  googleTranslateUrl: 'https://translation.googleapis.com/language/translate/v2',
  googleAnalytics: 'UA-105699516-3',
  googleTagManager: 'GTM-ML6W37V',
  recaptchaV3Key: '6Lf6Gt4ZAAAAAI6eNsP2VBTT5WnXMDfO7USIdHMT',
  userFlowToken: 'ct_xcnl7n3wtzeahdtpxqsrwptqhe',
  mixpanelProjectToken: 'bb4d1519c9b5aab5fff158312cfdfa16',
  environmentName: 'development',
  isSingleTenantApp: false,
  singleTenantInitConfigUrl: (singleTenantName: string = 'development'): string =>
    `https://sloneek-dev.s3.eu-west-1.amazonaws.com/single-tenant-config/${singleTenantName}.json`,
};
