import { Injectable } from '@angular/core';

import { take } from 'rxjs/operators';

import { ZendeskService } from '@app/api/v1/module-core/zendesk/zendesk.service';
import { V1_AUTH_USER_ROLE } from '@app/models/api/v1/auth/auth.enums';
import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';
import { LICENCE_TYPE } from '@app/sloneek/modules/sloneek-settings/modules/sloneek-settings-billing-v2/old/models/licenses';

declare const $hsq;

@Injectable({
  providedIn: 'root',
})
export class SupportChatService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private chatInstance: any;

  constructor(private zendeskService: ZendeskService) {
    this.chatInstance = $hsq;
  }

  private zendeskWidgetAction(action: unknown, event: unknown, parameters: unknown = null): void {
    // zendesk DOC https://developer.zendesk.com/api-reference/widget-messaging/web/core/
    // zE('messenger:<action>', '<event|property>', <parameters>);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).zE(action, event, parameters);
  }

  public addHideSupportChatClass(): void {
    document.body.classList.add('hide_support_chat');
  }

  public removeHideSupportChatClass(): void {
    document.body.classList.remove('hide_support_chat');
  }

  // TODO AFTER REMOVE OLD NAVIGATION REMOVE THIS FUNCTIONS
  public addHideCrispClass(): void {
    document.body.classList.add('hide_crisp');
  }

  public addShowCrispClass(): void {
    document.body.classList.remove('hide_crisp');
  }

  public addHideCrispOnMobileClass(): void {
    document.body.classList.add('hide_crisp_on_mobile');
  }

  public addShowCrispOnMobileClass(): void {
    document.body.classList.remove('hide_crisp_on_mobile');
  }

  public showSupportChat(): void {
    // @ts-ignore
    window.HubSpotConversations?.widget.open();
  }

  private initZendesk(user: V1AuthUser): void {
    this.zendeskWidgetAction('messenger:set', 'locale', user.language);
    this.zendeskWidgetAction('messenger', 'loginUser', callback => {
      this.zendeskService
        .getZendeskUserToken()
        .pipe(take(1))
        .subscribe(res => {
          callback(res);
        });
    });
  }

  private logoutZendesk(): void {
    this.zendeskWidgetAction('messenger', 'logoutUser');
  }

  public logoutSupportChat(): void {
    this.logoutZendesk();
  }

  public initSupportChat(user: V1AuthUser): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const identifyObject: any = {};
    // @ts-ignore
    if (this.chatInstance) {
      identifyObject.id = user.uuid;
      identifyObject.email = user.email;
      if (user.phone_number) {
        identifyObject.phonenumber = user.phone_number;
        identifyObject.phone = user.phone_number;
        identifyObject.mobilephone = user.phone_number;
      }
      identifyObject.firstname = user.name;
      identifyObject.lastname = user.surname;

      identifyObject.nickname = user.full_name;
      identifyObject.company = user.company.company_name;
      if (user.avatar_url) {
        identifyObject.avatar = user.avatar_url;
      }

      Object.keys(user.modules).forEach(data => {
        identifyObject['user_company_module_' + data] = user.modules[data];
      });
      identifyObject.hs_language = user.language;
      identifyObject.user_is_owner = user.roles.includes(V1_AUTH_USER_ROLE.OWNER);
      identifyObject.user_is_admin = user.roles.includes(V1_AUTH_USER_ROLE.ADMIN);
      identifyObject.user_is_manager = user.roles.includes(V1_AUTH_USER_ROLE.MANAGER);
      identifyObject.user_is_user = user.roles.includes(V1_AUTH_USER_ROLE.USER);
      if (user.company.licence?.type === LICENCE_TYPE.REGULAR && user.company.licence?.licence_package) {
        const examplePackage = user.company.licence?.licence_package.code;
        const codeParsed = examplePackage.split('-');
        if (codeParsed.length > 2) {
          const packageId = codeParsed[1];
          identifyObject.user_company_package = packageId.charAt(0).toUpperCase() + packageId.slice(1);
        }
      }
    }
    try {
      this.chatInstance.push(['identify', identifyObject]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    this.chatInstance.push(['trackPageView']);
  }

  public trackRouteChance(route: string): void {
    this.chatInstance.push(['setPath', route]);
    this.chatInstance.push(['trackPageView']);
  }
}
