<div class="row no-gutters align-items-center">
  <div class="col-auto" *ngIf="showBackButton">
    <a class="pointer" [routerLink]="defaultBackButtonRoute">
      <nb-icon icon="arrow-left"></nb-icon>
    </a>
  </div>
  <div class="col">
    <ng-content>
    </ng-content>
  </div>
  <!--  <div class="col-auto">-->
  <!--    <div style="height: 0">-->
  <!--      <ng-content select=".header_buttons"></ng-content>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="col-auto pl-2" *ngIf="showCloseButton">
    <a class="pointer" (click)="close()">
      <i class="fas fa-times text-primary"></i>
    </a>
  </div>
</div>
