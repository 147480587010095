import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable, of } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeEnglish from '@angular/common/locales/en-GB';
import localeSlovak from '@angular/common/locales/sk';
import localeCzech from '@angular/common/locales/cs';
import localeFrance from '@angular/common/locales/fr';
import localeGerman from '@angular/common/locales/de';
import localeSpanish from '@angular/common/locales/es';
import localeItalian from '@angular/common/locales/it';
import localeHungary from '@angular/common/locales/hu';
import localePoland from '@angular/common/locales/pl';
import { INITIAL_LANGUAGE, TESTING_LANGUAGE_CODE } from '../../config';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { LocalizationLang } from '../models/localization-lang';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private availableLanguages: LocalizationLang[];
  private _locale: string;

  constructor(
    @Optional() @SkipSelf() otherInstance: LocalizationService) {
    this.availableLanguages = [
      {
        name: 'English',
        icon: 'gb',
        code: 'en',
      },
      {
        name: 'German',
        icon: 'de',
        code: 'de',
      },
      {
        name: 'Spanish',
        icon: 'es',
        code: 'es',
      },
      {
        name: 'Italian',
        icon: 'it',
        code: 'it',
      },
      {
        name: 'Polish',
        icon: 'pl',
        code: 'pl',
      },
      {
        name: 'Slovak',
        icon: 'sk',
        code: 'sk',
      },
      {
        name: 'Czech',
        icon: 'cz',
        code: 'cs',
      },
      {
        name: 'Hungarian',
        icon: 'hu',
        code: 'hu',
      },
    ];
    if (!environment.production) {
      this.availableLanguages.push({
        name: 'French',
        icon: 'fr',
        code: 'fr',
      }, {
        name: 'Testing',
        icon: 'im',
        code: TESTING_LANGUAGE_CODE,
      });
    }
    if (otherInstance) {
      console.error('LocaleService should have only one instance.');
    }
  }

  set locale(value: string) {
    this._locale = value;
  }

  get locale(): string {
    return this._locale || INITIAL_LANGUAGE;
  }

  public getAvailableLanguagesWithoutTesting(): Observable<LocalizationLang[]> {
    return this.getAvailableLanguages().pipe(map(languages => {
      return languages.filter(language => language.code !== TESTING_LANGUAGE_CODE);
    }));
  }

  public getAvailableLanguages(): Observable<LocalizationLang[]> {
    return of(this.availableLanguages);
  }

  registerLocale(languageCode: string) {
    if (!languageCode || languageCode === TESTING_LANGUAGE_CODE) {
      return;
    }
    this.locale = languageCode;
    moment.locale(languageCode);
    // this.nebularDateService.setLocale(languageCode);
    switch (languageCode) {
      case 'en': {
        registerLocaleData(localeEnglish);
        break;
      }
      case 'sk': {
        registerLocaleData(localeSlovak);
        break;
      }
      case 'cs': {
        registerLocaleData(localeCzech);
        break;
      }
      case 'de': {
        registerLocaleData(localeGerman);
        break;
      }
      case 'fr': {
        registerLocaleData(localeFrance);
        break;
      }
      case 'it': {
        registerLocaleData(localeItalian);
        break;
      }
      case 'es': {
        registerLocaleData(localeSpanish);
        break;
      }
      case 'hu': {
        registerLocaleData(localeHungary);
        break;
      }
      case 'pl': {
        registerLocaleData(localePoland);
        break;
      }
    }
  }
}
