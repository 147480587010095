import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SkipSuccessToastInterceptor, SkipToastInterceptor } from '@app/helpers/skip-toast';
import { ToastHandlerService } from '@app/shared/services/toast-handler.service';

@Injectable()
export class ToastHandlerInterceptor implements HttpInterceptor {
  constructor(private toast: ToastHandlerService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hadSkipSuccessToastInterceptor = request.headers.has(SkipSuccessToastInterceptor);
    if (request.headers.has(SkipToastInterceptor) || request.headers.has(SkipSuccessToastInterceptor)) {
      const headers = request.headers.delete(SkipToastInterceptor).delete(SkipSuccessToastInterceptor);
      return next.handle(request.clone({ headers }));
    }
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (request.method === 'PUT' || request.method === 'DELETE' || request.method === 'POST' || request.method === 'PATCH') {
            if (request.url.includes('/auth/refresh')) {
              return event;
            } else {
              if (event.status !== 204 && !hadSkipSuccessToastInterceptor) {
                this.toast.showSuccessToastsResponse(event.body.message);
              }
            }
          }
        }
        return event;
      }),
      catchError(err => {
        if (!err.error?.token_expired) {
          try {
            this.toast.showErrorToastsResponse(err);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
