import {
  fadeInOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  fadeOutRightOnLeaveAnimation,
  fadeInUpOnEnterAnimation,
} from 'angular-animations';

export const displayPageOnUpAnimation = fadeInUpOnEnterAnimation({
  anchor: 'displayPageOnUpAnimation',
  duration: 200,
});

export const displayPageButtonsOnRightAnimation = fadeInRightOnEnterAnimation({
  anchor: 'displayPageButtonsOnRightAnimation',
  duration: 200,
});

export const removePageButtonsOnRightAnimation = fadeOutRightOnLeaveAnimation({
  anchor: 'removePageButtonsOnRightAnimation',
  duration: 200,
});

export const displayPageTitleAnimation = fadeInOnEnterAnimation({ anchor: 'displayPageTitleAnimation', duration: 300 });
