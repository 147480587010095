import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

import { ToastHandlerService } from '@app/shared/services/toast-handler.service';
import { notificationActions } from '../actions/notification.actions';

@Injectable()
export class NotificationEffects {
  public readonly notifyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.error),
        tap(({ title, message, translate, showDefaultMessage }) =>
          this.toastService.showErrorToast(
            title ?? '',
            message ? (translate ? this.translateService.instant(message) : message) : '',
            null,
            showDefaultMessage ?? false,
          ),
        ),
      ),
    { dispatch: false },
  );

  public readonly notifySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.success),
        tap(({ title, message, translate }) =>
          this.toastService.showSuccessToast(message ? (translate ? this.translateService.instant(message) : message) : '', title ?? ''),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private toastService: ToastHandlerService,
  ) {}
}
