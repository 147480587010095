export const BACKEND_DAY_FORMAT = 'YYYY-MM-DD';

export const IMAGE_FILE_UPLOAD_TYPES = '.jpg, .jpeg, .png';
export const PDF_FILE_UPLOAD_TYPE = 'application/pdf';
export const DOCUMENT_FILE_UPLOAD_TYPES = '.doc, .docx ';
export const PRESENTATION_FILE_UPLOAD_TYPES = '.ppt, .pptx';
export const TABLE_FILE_UPLOAD_TYPES = '.xls, .xlsx, .csv';

export const PLANNING_EVENT_DOCUMENTS_UPLOAD_TYPES =
  IMAGE_FILE_UPLOAD_TYPES +
  ',' +
  PDF_FILE_UPLOAD_TYPE +
  ', ' +
  DOCUMENT_FILE_UPLOAD_TYPES +
  ', ' +
  PRESENTATION_FILE_UPLOAD_TYPES +
  ', ' +
  TABLE_FILE_UPLOAD_TYPES;

export const IMAGE_MAX_FILE_UPLOAD_SIZE_MB = 2;

export const LANGUAGE_LOCAL_STORAGE_KEY = 'local-translate';

export const JWT_TOKEN_STORAGE = 'sloneek-access-token';
export const JWT_REFRESH_TOKEN_STORAGE = 'sloneek-refresh-token';
// TODO: remove if not needed
export const JWT_SOCKET_TOKEN_STORAGE = 'sloneek-socket-token';
export const ADMIN_LOGGED = 'sloneek-is-admin-logged';
export const USER_DATA = 'sloneek-user';
export const TERMINAL_DATA = 'sloneek-terminal';
export const INITIAL_LANGUAGE = 'en';

export const GRID_TYPE_EXTENDED = 'gridTypeExtended';

export enum DEFAULT_PHOTOS {
  ASSETS_PHOTO = './assets/images/static/box-open-solid.svg',
  COMPANY_PHOTO = './assets/images/static/building-solid.svg',
  USER_PHOTO = './assets/images/static/user-regular.png',
}

export const TESTING_LANGUAGE_CODE = 'test';

export const h = {
  message: 'OK',
  status_code: 200,
  data: [
    {
      title: 'receiver_parameters',
      categories: [
        {
          title: 'receiver_parameters_personal_information',
          placeholders: {
            private_phone_number: null,
            private_email: null,
            birth_date: null,
            identification_number: null,
            health_insurance: null,
            residence_address: null,
            bank_account_number: null,
            bank_account_iban: null,
            bank_code: null,
            bank_account_owner: null,
          },
        },
        {
          title: 'receiver_parameters_work_information',
          placeholders: {
            name: null,
            surname: null,
            email: null,
            join_date: null,
            left_date: null,
            phone_number: null,
            internal_phone_number: null,
            team: null,
            position: null,
            internal_number: null,
            card_id: null,
            location: null,
          },
        },
        {
          title: 'receiver_parameters_work_information_and_salary',
          placeholders: {
            working_hour_type: null,
            working_hour_valid_from: null,
            working_hour_valid_to: null,
            user_salary_basic_salary: null,
            user_salary_from_date: null,
            user_salary_to_date: null,
            user_salary_deduction_name: null,
            user_salary_deduction_value_and_currency: null,
            user_salary_deduction_date: null,
            user_salary_bonus_name: null,
            user_salary_bonus_value_and_currency: null,
            user_salary_bonus_date: null,
            user_meal_voucher_value_and_currency: null,
            user_meal_voucher_minimum_worked_hours: null,
          },
        },
        {
          title: 'receiver_parameters_custom_fields',
          placeholders: {
            'cislo topanok pre vsetkych': null,
            'datum pole len admin': null,
            'pripomienka len admin': null,
            'Vyrocninca vsetci': null,
            'TextField len admini': null,
            'uplne novy field': null,
            'Personal NOvy': null,
            'Text field pre vsetkych': null,
          },
        },
      ],
    },
    {
      title: 'company_representative_parameters',
      categories: [
        {
          title: 'company_representative_parameters_personal_information',
          placeholders: {
            company_owner_private_phone_number: null,
            company_owner_private_email: null,
            company_owner_birth_date: null,
            company_owner_identification_number: null,
            company_owner_health_insurance: null,
            company_owner_residence_address: null,
            company_owner_contact_address: null,
            company_owner_bank_account_number: null,
            company_owner_bank_account_iban: null,
            company_owner_bank_code: null,
            company_owner_bank_account_owner: null,
          },
        },
        {
          title: 'company_representative_parameters_work_information',
          placeholders: {
            company_owner_name: null,
            company_owner_surname: null,
            company_owner_email: null,
            company_owner_join_date: null,
            company_owner_left_date: null,
            company_owner_phone_number: null,
            company_owner_team: null,
            company_owner_position: null,
            company_owner_internal_number: null,
            company_owner_card_id: null,
            company_owner_location: null,
          },
        },
        {
          title: 'company_representative_parameters_custom_fields',
          placeholders: {
            'cislo topanok pre vsetkych': null,
            'datum pole len admin': null,
            'pripomienka len admin': null,
            'Vyrocninca vsetci': null,
            'TextField len admini': null,
            'uplne novy field': null,
            'Personal NOvy': null,
            'Text field pre vsetkych': null,
          },
        },
      ],
    },
    {
      title: 'company_parameters',
      categories: [
        {
          title: 'company_parameters_information',
          placeholders: {
            company_name: null,
            company_email: null,
            company_contact_person: null,
            company_phone: null,
            company_street: null,
            company_zip: null,
            company_city: null,
            company_country: null,
            company_country_region: null,
          },
        },
      ],
    },
  ],
  errors: [],
};
