import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@app/environments/environment';
import { getSkipSuccessToastInterceptor, getSkipToastInterceptor } from '@app/helpers/skip-toast';
import {
  GET_V1_AuthMeResponse,
  POST_V1_AuthChangeLanguageParams,
  POST_V1_AuthChangeLanguageResponse,
  POST_V1_AuthForgotPasswordParams,
  POST_V1_AuthForgotPasswordResponse,
  POST_V1_AuthHoldingLoginParams,
  POST_V1_AuthHoldingLoginResponse,
  POST_V1_AuthLoginParams,
  POST_V1_AuthLoginResponse,
  POST_V1_AuthLogoutHostUserResponse,
  POST_V1_AuthPasswordSettingsParams,
  POST_V1_AuthPasswordSettingsResponse,
  POST_V1_AuthRefreshTokenParams,
  POST_V1_AuthRefreshTokenResponse,
  POST_V1_AuthResendMFACodeParams,
  POST_V1_AuthResendMFACodeResponse,
  POST_V1_AuthSetMFAParams,
  POST_V1_AuthSetMFAResponse,
  POST_V1_AuthSetPasswordParams,
  POST_V1_AuthSetPasswordResponse,
  POST_V1_AuthSkipMFAParams,
  POST_V1_AuthSkipMFAResponse,
  POST_V1_AuthTerminalLoginParams,
  POST_V1_AuthTerminalLoginResponse,
  POST_V1_AuthTokenLoginParams,
  POST_V1_AuthTokenLoginResponse,
  POST_V1_AuthVerifyEmailParams,
  POST_V1_AuthVerifyEmailResponse,
  POST_V1_AuthVerifyMFACodeParams,
  POST_V1_AuthVerifyMFACodeResponse,
  PUT_V1_AuthChangePasswordParams,
  PUT_V1_AuthChangePasswordResponse,
} from '@app/models/api/v1/auth/auth.model';

@Injectable()
export class V1_AuthService {
  private readonly _authApiRoute = `${environment.backendUrl}auth`;
  private readonly _authApiRouteV1 = `${environment.backendUrl}v1/auth`;
  private readonly _http = inject(HttpClient);

  /**
   * Standard login end-point using email and password.
   */
  public standardLogin(params: POST_V1_AuthLoginParams): Observable<POST_V1_AuthLoginResponse> {
    return this._http.post<POST_V1_AuthLoginResponse>(`${this._authApiRoute}/login`, params, { headers: getSkipSuccessToastInterceptor() });
  }

  /**
   * Refresh auth tokens using refresh token.
   */
  public refreshToken(params: POST_V1_AuthRefreshTokenParams): Observable<POST_V1_AuthRefreshTokenResponse> {
    return this._http.post<POST_V1_AuthRefreshTokenResponse>(`${this._authApiRoute}/refresh`, params);
  }

  /**
   * Gets user object with user info for the current user.
   */
  public getUserInfo(): Observable<GET_V1_AuthMeResponse> {
    return this._http.get<GET_V1_AuthMeResponse>(`${this._authApiRoute}/me`);
  }

  /**
   * Terminal login end-point using access code.
   */
  public terminalLogin(params: POST_V1_AuthTerminalLoginParams): Observable<POST_V1_AuthTerminalLoginResponse> {
    return this._http.post<POST_V1_AuthTerminalLoginResponse>(`${this._authApiRoute}/terminal-login`, params);
  }

  /**
   * Used to login/swapping between companies which belong to the same holding.
   */
  public holdingLogin(params: POST_V1_AuthHoldingLoginParams): Observable<POST_V1_AuthHoldingLoginResponse> {
    return this._http.post<POST_V1_AuthHoldingLoginResponse>(`${this._authApiRoute}/login-to-holding-company`, params);
  }

  /**
   * Used to login using token after successful registration.
   * @param `token` - is received after calling `/auth/register-web` end-point from the website.
   */
  public tokenLogin(params: POST_V1_AuthTokenLoginParams): Observable<POST_V1_AuthTokenLoginResponse> {
    return this._http.post<POST_V1_AuthTokenLoginResponse>(`${this._authApiRoute}/login-token`, params);
  }

  /**
   * Sents a forgot password email to the user.
   */
  public forgotPassword(params: POST_V1_AuthForgotPasswordParams): Observable<POST_V1_AuthForgotPasswordResponse> {
    return this._http.post<POST_V1_AuthForgotPasswordResponse>(`${this._authApiRoute}/forgot-password`, params);
  }

  /**
   * Changes pasword of currently logged in user with old password, new password and password confirmation.
   */
  public changePassword(params: PUT_V1_AuthChangePasswordParams): Observable<PUT_V1_AuthChangePasswordResponse> {
    return this._http.put<PUT_V1_AuthChangePasswordResponse>(`${this._authApiRoute}/change-password`, params);
  }

  /**
   * Resets the password for the user with new password, password confirmation and token.
   */
  public setNewPassword(params: POST_V1_AuthSetPasswordParams): Observable<POST_V1_AuthSetPasswordResponse> {
    return this._http.post<POST_V1_AuthSetPasswordResponse>(`${this._authApiRoute}/set-password`, params);
  }

  /**
   * Verifies an email after user clicks on the link in the email.
   */
  public verifyEmail(params: POST_V1_AuthVerifyEmailParams): Observable<POST_V1_AuthVerifyEmailResponse> {
    return this._http.post<POST_V1_AuthVerifyEmailResponse>(`${this._authApiRoute}/email-verification/response`, params);
  }

  /**
   * Used to logout as hosted user and return original admin user who was logged in.
   * Available only as Sloneek admin.
   */
  public logoutHostUser(): Observable<POST_V1_AuthLogoutHostUserResponse> {
    return this._http.post<POST_V1_AuthLogoutHostUserResponse>(`${this._authApiRoute}/back-to-bath`, {});
  }

  /**
   * Changes user's current language.
   */
  public changeUserLanguage(params: POST_V1_AuthChangeLanguageParams): Observable<POST_V1_AuthChangeLanguageResponse> {
    return this._http.put<POST_V1_AuthChangeLanguageResponse>(`${this._authApiRoute}/me/language`, params, {
      headers: getSkipToastInterceptor(),
    });
  }

  /**
   * Fetches password setting policy for anonymous user with token.
   * Token can be fetched from `/auth/forgot-password` end-point via email.
   */
  public getPasswordSettings(params: POST_V1_AuthPasswordSettingsParams): Observable<POST_V1_AuthPasswordSettingsResponse> {
    return this._http.get<POST_V1_AuthPasswordSettingsResponse>(`${this._authApiRoute}/password-settings/${params.token}`);
  }

  /**
   * Sets up MFA for the user.
   */
  public setupMFA(params: POST_V1_AuthSetMFAParams): Observable<POST_V1_AuthSetMFAResponse> {
    return this._http.post<POST_V1_AuthSetMFAResponse>(`${this._authApiRouteV1}/mfa/phone-number`, params);
  }

  /**
   * Skips MFA for the user, can be pernamently skipped or skipped for a certain time.
   */
  public skipMFA(params: POST_V1_AuthSkipMFAParams): Observable<POST_V1_AuthSkipMFAResponse> {
    return this._http.post<POST_V1_AuthSkipMFAResponse>(`${this._authApiRouteV1}/mfa/skip-verification`, params);
  }

  /**
   * Verifies MFA code entered by the user, if success response is returned, user is logged in.
   */
  public verifyMFACode(params: POST_V1_AuthVerifyMFACodeParams): Observable<POST_V1_AuthVerifyMFACodeResponse> {
    return this._http.post<POST_V1_AuthVerifyMFACodeResponse>(`${this._authApiRouteV1}/mfa/verify-code`, params);
  }

  /**
   * Sends a new MFA code to the user's phone number.
   */
  public resendMFACode(params: POST_V1_AuthResendMFACodeParams): Observable<POST_V1_AuthResendMFACodeResponse> {
    return this._http.post<POST_V1_AuthResendMFACodeResponse>(`${this._authApiRouteV1}/mfa/resend-code`, params);
  }
}
